<template>
    <div class="w-full">
        <div
            class="grid grid-flow-col justify-stretch mb-6"
            :class="tabClasses"
        >
            <NuxtLink
                v-for="tab in tabs"
                :key="tab.id"
                :to="tab.to"
                class="pb-1 px-4 hover:border-black hover:border-b-2 transition-colors uppercase cursor-pointer text-sm lg:text-base"
                :class="{
                    'border-b-2 border-black': !!tab.selected,
                    'border-b-[1.5px] border-gray-50': !tab.selected,
                    'font-semibold': !!tab.selected && tabsBold,
                    'text-left': tabsLeft,
                    'text-center': !tabsLeft,
                }"
                @click="emits('changeTab', { tab: tab.id })"
            >
                {{ tab.title }}
            </NuxtLink>
        </div>
        <div>
            <div
                class="max-w-md mx-auto"
                :class="tabContentClasses"
            >
                <slot />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
interface Tab {
    id: string;
    title: string;
    to?: string;
    selected?: boolean;
}

withDefaults(
    defineProps<{
        tabs: Tab[];
        tabsBold?: boolean;
        tabsLeft?: boolean;
        tabClasses?: string;
        tabContentClasses?: string;
    }>(),
    {
        tabsBold: true,
        tabsLeft: false,
        tabClasses: "",
        tabContentClasses: "",
    },
);

const emits = defineEmits(["changeTab"]);
</script>
